import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import NewsList from "../component/NewsList";
import MobileFooter from "../component/MobileFooter";
import { HeaderCard } from "../component/HeaderCard";
import MobileHeaderCard from "../component/MobileHeaderCard";
// import SubHeader from "../component/SubHeader";
import { Link } from "react-router-dom";
import SideAds from "../component/SideAds";
const NewsPage = () => {
  return (
    <div className="bg-[url('https://i.ibb.co/3pyr58R/betuptip.png')] object-fill bg-fixed bg-fill bg-center">
      <div className="bg-black opacity-50 fixed  inset-0 z-2 w-screen h-screen"></div>
      <div className="m-0 p-0 relative">
        <SideAds />
        <HeaderCard />
        <MobileHeaderCard />
        {/* <SubHeader /> */}
        <div className="lg:mx-96 mx-0 mt-0 text-white bg-black p-4">
          <div className="border-0 lg:border border-white border-opacity-30 rounded-md p-1">
            <Link to="/Football">
              <div className="flex mx-auto items-center justify-between px-5 py-4 border-0 lg:border-b border-white border-opacity-30">
                <p className="font-semibold text-gray-400">Football News</p>
                <MdOutlineKeyboardArrowRight className="h-5 w-5 " />
              </div>
            </Link>
            <div className="py-6">
              <NewsList />
            </div>
          </div>
        </div>
        <div>
          <MobileFooter />
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
