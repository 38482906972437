import React from "react";
// import { IoIosFootball } from "react-icons/io";
import { IoNewspaper } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { NavLink } from "react-router-dom";

const MobileFooter = () => {
  return (
    <div className="lg:hidden bg-black py-5 fixed bottom-0 left-0 z-50 w-full">
      <ul className="flex gap-24 md:gap-56  justify-center items-center">
        <NavLink
          to="/Home"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col items-center text-yellow-600"
              : "flex flex-col items-center text-gray-400 hover:text-yellow-600"
          }
        >
          <GoHomeFill className="w-12 h-12" />
          <p className="font-bold text-lg">Home</p>
        </NavLink>

        {/* <NavLink
          to="/Livescore"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col items-center text-yellow-600"
              : "flex flex-col items-center text-gray-400 hover:text-yellow-600"
          }
        >
          <IoIosFootball className="w-12 h-12" />
          <p className="font-bold text-lg">Score</p>
        </NavLink> */}

        <NavLink
          to="/News"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col items-center text-yellow-600"
              : "flex flex-col items-center text-gray-400 hover:text-yellow-600"
          }
        >
          <IoNewspaper className="w-12 h-12" />
          <p className="font-bold text-lg">News</p>
        </NavLink>

        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col items-center text-yellow-600"
              : "flex flex-col items-center text-gray-400 hover:text-yellow-600"
          }
        >
          <MdOutlineTipsAndUpdates className="w-12 h-12" />
          <p className="font-bold text-lg">Get Tips</p>
        </NavLink>
      </ul>
    </div>
  );
};

export default MobileFooter;
