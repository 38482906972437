import React from "react";

interface ImageSectionProps {
  src: string;
  alt: string;
  additionalStyles?: string;
}

const ImageSection = ({
  src,
  alt,
  additionalStyles = "",
}: ImageSectionProps) => {
  return (
    <div>
      <img src={src} alt={alt} className={`mt-5 ${additionalStyles}`} />
    </div>
  );
};

export default ImageSection;
