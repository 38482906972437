import React, { useEffect, useRef, useState } from "react";
import { AlertProps } from "../utilities/types";

const Alert = ({ content }: AlertProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (content.message && content.message !== "") return setShowAlert(true);
  }, [content]);

  useEffect(() => {
    if (showAlert) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setShowAlert(false), 2000);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [showAlert]);

  const closeAlert = () => {
    return setShowAlert(false);
  };

  return (
    <div
      className={`alert ${
        content.status === "success" ? "alert-success" : "alert-danger"
      } ${showAlert && content.message !== "" ? "show" : "hide"}`}
    >
      <p className="text">{content.message}</p>
      <span className="icon">
        <span className="close hairline" onClick={closeAlert}></span>
      </span>
    </div>
  );
};

export default Alert;
