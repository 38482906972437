import React from "react";
import { FaRegStar } from "react-icons/fa";
import { PiOctagonFill } from "react-icons/pi";

// Define the types for the props
interface ScoreApiCardProps {
  team1: string;
  team2: string;
  score1: number;
  score2: number;
  team1Color: string;
  team2Color: string;
}

// Reusable Card Component
const ScoreApiCard: React.FC<ScoreApiCardProps> = ({
  team1,
  team2,
  score1,
  score2,
  team1Color,
  team2Color,
}) => (
  <div className="flex items-center justify-between mx-auto bg-white bg-opacity-10 rounded-2xl mt-5 py-8 px-6">
    {/* Teams and their icons */}
    <div className="flex items-center gap-20">
      <div className="font-semibold text-[14px] text-yellow-600">RT</div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-3 text-bold">
          <PiOctagonFill className={`text-${team1Color}`} />
          <p className="text-gray-400">{team1}</p>
        </div>

        <div className="flex items-center gap-3 text-bold">
          <PiOctagonFill className={`text-${team2Color}`} />
          <p className="text-gray-400">{team2}</p>
        </div>
      </div>
    </div>

    {/* Number and Star */}
    <div className="flex items-center gap-8 lg:gap-10">
      <div>
        <p className="font-bold">{score1}</p>
        <p className="font-bold">{score2}</p>
      </div>
      <FaRegStar className="h-8 w-8 lg:h-10 lg:w-10 hover:text-yellow-600" />
    </div>
  </div>
);

const ScoreCards = () => {
  //multiple cards
  const cardData = [
    {
      team1: "El Kanemi Warriors",
      team2: "Zara United",
      score1: 3,
      score2: 2,
      team1Color: "red-400",
      team2Color: "green-400",
    },
    {
      team1: "Team A",
      team2: "Team B",
      score1: 1,
      score2: 4,
      team1Color: "blue-400",
      team2Color: "yellow-400",
    },
    {
      team1: "Team X",
      team2: "Team Y",
      score1: 0,
      score2: 0,
      team1Color: "purple-300",
      team2Color: "pink-400",
    },
    // Add as many items as you need
  ];

  return (
    <div>
      {/* Map through cardData and render ScoreApiCard for each item */}
      {cardData.map((card, index) => (
        <ScoreApiCard
          key={index}
          team1={card.team1}
          team2={card.team2}
          score1={card.score1}
          score2={card.score2}
          team1Color={card.team1Color} //change all colors to icons later
          team2Color={card.team2Color}
        />
      ))}
    </div>
  );
};

export default ScoreCards;
