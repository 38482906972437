import React, { FormEvent, useEffect } from "react";
import Layout from "../hoc/Layout";
import { useTypedSelector } from "../store/store";
import { useNavigate } from "react-router-dom";
import { ButtonLoader } from "../component/Loader";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import FormInput from "../component/FormInput";
import useForm from "../Hooks/useForm";
import useAlert from "../Hooks/useAlert";
import Alert from "../component/Alert";
import { axiosConfig } from "../utilities/helpers";
import { BaseResponse, LoginProps } from "../utilities/types";

const API_ENDPOINT = "/auth/reset-password";
const NAVIGATION_DELAY = 2400;

const PasswordReset = () => {
  const navigate = useNavigate();
  const { accessToken } = useTypedSelector((state) => state.auth);
  const { formState, handleChange } = useForm({
    email: "",
    password: "",
  });

  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    BaseResponse,
    LoginProps
  >();

  const [alert] = useAlert(data, error);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        resetData();
        navigate("/login");
      }, NAVIGATION_DELAY);
    }
  }, [data, error, navigate, resetData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const resetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData(API_ENDPOINT, "POST", axiosConfig(accessToken), formState);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register">
            <div className="register__img"></div>
            <div className="register__form register--top">
              <h3>Reset password</h3>
              <form className="form" onSubmit={resetPassword}>
                <FormInput
                  id="email"
                  type="email"
                  label="Email"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="password"
                  type="password"
                  label="New Password"
                  value={formState.password}
                  onChange={handleInputChange}
                />
                <div className="input__group submit">
                  <button
                    type="submit"
                    className="btn btn-pry btn-lrg btn-round btn-block"
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PasswordReset;
