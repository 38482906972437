import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { HiMiniPaperClip } from "react-icons/hi2";

const SocialIcons = () => {
  return (
    <div className="flex gap-4">
      <FaFacebookSquare className="text-gray-400 h-8 w-8" />
      <BsTwitterX className="text-gray-400 h-8 w-8" />
      <HiMiniPaperClip className="text-gray-400 h-8 w-8" />
    </div>
  );
};

export default SocialIcons;
