import React, { useEffect, useState } from "react";
import MainArticle from "./MainArticle";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../Hooks/useAlert";
import { iNews, NewsDetailsResponse } from "../utilities/types";
import { axiosInstance, handleError } from "../utilities/helpers";
import { PageLoader } from "./Loader";
import Alert from "./Alert";

const NewsArticle = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the `id` parameter from the URL
  const [alert, setAlert] = useAlert();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<iNews>();

  useEffect(() => {
    if (!id)
      return setAlert({ status: "error", message: "No article ID provided!" });

    setLoading(true);
    axiosInstance()
      .get<NewsDetailsResponse>(`/news/${id}`)
      .then(({ data }) => {
        setNews(data.data);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setLoading(false));
  }, [id, navigate, setAlert]);

  if (loading) return <PageLoader />;

  return (
    <div>
      <Alert content={alert} />
      {news ? (
        <MainArticle {...news} />
      ) : (
        <div>No article found for the given ID.</div>
      )}
    </div>
  );
};

export default NewsArticle;
