import React, { useEffect, FormEvent, useState } from "react";
import Layout from "../hoc/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { setEmail } from "../store/slices/authSlice";
import { ButtonLoader } from "../component/Loader";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import { axiosInstance, handleError } from "../utilities/helpers";
import { BaseResponse } from "../utilities/types";
import useAlert from "../Hooks/useAlert";
import Alert from "../component/Alert";
const RESET_URL = "/auth/forgot-password";
const RESEND_URL = "/auth/resend-password-otp";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [alert, setAlert] = useAlert();
  const [loading, setLoading] = useState(false);
  const { formState, handleChange } = useForm({ email: "" });

  useEffect(() => {
    dispatch(setEmail({ email: formState.email }));
  }, [dispatch, formState.email]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const resetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance()
      .post<BaseResponse>(RESET_URL, formState)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setLoading(false));
  };

  const resendOTP = () => {
    setLoading(true);
    axiosInstance()
      .post<BaseResponse>(RESEND_URL, formState)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setLoading(false));
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register">
            <div className="register__img"></div>
            <div className="register__form register--top">
              <h3>Forgot password</h3>
              <form className="form" onSubmit={resetPassword}>
                <FormInput
                  id="email"
                  type="email"
                  label="Email"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <div className="input__group">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`btn btn-pry btn-lrg btn-round btn-block ${
                      loading ? "btn-disabled" : ""
                    }`}
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
                <div className="input__group">
                  <div className="flex flex--between">
                    <p>
                      did not get an otp?
                      <Link to="#" onClick={resendOTP}>
                        resend otp
                      </Link>
                    </p>
                    <p className="text-right">
                      <a href="/forgot-password-verify">Verify OTP</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
