import React from "react";
import { HeaderCard } from "../component/HeaderCard";
import MobileHeaderCard from "../component/MobileHeaderCard";
import MobileFooter from "../component/MobileFooter";
// import SubHeader from "../component/SubHeader";
import NewsArticle from "../component/NewsArticle";
// import RelatedArticleCard from "../component/RelatedArticleCard";
import SideAds from "../component/SideAds";

const Predictions = () => {
  return (
    <div className="bg-[url('https://i.ibb.co/3pyr58R/betuptip.png')] object-fill bg-fixed bg-fill bg-center">
      <div className="bg-black opacity-50 fixed  inset-0 z-2 w-screen h-screen"></div>
      <div className="relative">
        <SideAds />
        <HeaderCard />
        <MobileHeaderCard />
        {/* <SubHeader /> */}
        <div className="lg:flex lg:px-96">
          <div className="w-full text-white bg-black p-4 lg:w-[100%] ">
            <div className="lg:border border-1 border-white border-opacity-30 rounded-lg lg:px-5 lg:pt-10">
              <NewsArticle />
            </div>
            {/* tags */}
            <div className="lg:hidden block border-b border-t border-white border-opacity-30 font-bold">
              <p className="p-4">TAGS</p>
            </div>
          </div>
          {/* related items */}
          {/* <div className="w-full py-40 text-white bg-black p-4 lg:w-[40%]">
            <div className=" lg:border border-1 border-white border-opacity-30 rounded-lg lg:px-6 px-0">
              {" "}
              <p className="font-bold mb-10 lg:border-b border-white border-opacity-30 px-4 lg:py-6 lg:px-5">
                RELATED ARTICLES
              </p>
              <RelatedArticleCard />
              <RelatedArticleCard />
              <RelatedArticleCard />
              <RelatedArticleCard />
              <RelatedArticleCard />
              <RelatedArticleCard />
              <RelatedArticleCard />
            </div>
          </div> */}
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};

export default Predictions;
