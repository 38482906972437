import React, { useEffect, useState } from "react";
import NewsCard from "./NewsCard";
import { useNavigate } from "react-router-dom";
import useAlert from "../Hooks/useAlert";
import { iNews, NewsResponse } from "../utilities/types";
import { axiosInstance, handleError } from "../utilities/helpers";
import Alert from "./Alert";
import { PageLoader } from "./Loader";

// const newsData = [
//   {
//     image: "https://i.ibb.co/NKxLchk/betuptip-News.webp",
//     title: "Wolves vs Bournemouth predictions: Wolves revival set to continue",
//     tags: ["Champions League", "Juventus", "Aston Villa"],
//     mobiletags: ["Juventus"],
//     time: "14 hours ago",
//   },
// ];

const NEWS_URL = "/news";

const NewsList = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useAlert();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<iNews[]>([]);

  useEffect(() => {
    setLoading(true);
    axiosInstance()
      .get<NewsResponse>(NEWS_URL)
      .then(({ data }) => {
        setNews(data.data);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setLoading(false));
  }, [navigate, setAlert]);

  if (loading) return <PageLoader />;

  return (
    <div className="flex flex-col gap-6">
      <Alert content={alert} />
      {news?.map((newsItem) => (
        <NewsCard
          key={newsItem._id}
          _id={newsItem._id}
          image={newsItem.image}
          title={newsItem.title}
          description={newsItem.description}
          // tags={news.tags}
          // mobiletags={news.mobiletags}
          // time={news.time}
        />
      ))}
    </div>
  );
};

export default NewsList;
