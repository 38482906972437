import React from "react";
import SocialIcons from "./SocialIcons";
import ImageSection from "./ImageSection";
import { iNews } from "../utilities/types";
// import InfoSection from "./InfoSection";

// interface ArticleProps {
//   title: string;
//   description: string;
//   authorName: string;
//   authorTitle: string;
//   date: string;
//   time: string;
//   mainImageSrc: string;
//   mainImageAlt: string;
//   infoItems: string[];
//   content: string[];
// }

const MainArticle = ({ title, description, image, content }: iNews) => {
  console.log(title, description, image, content);
  return (
    <div className="article-container">
      <div className="flex justify-between py-6">
        <p className="text-3xl font-semibold w-[70%] text-gray-200">{title}</p>
        <SocialIcons /> {/* Static Social Icons */}
      </div>

      {/* Author Info */}
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <img
            src="https://i.ibb.co/jknv1tp/360-F-511752565-2q45m-Etn-ZBs52z-Oof-TLtrt-JC0z-Eh-Mpp-E-removebg-preview.png"
            alt=""
            className="w-auto h-12"
          />
          <div>
            {/* <p className="font-semibold">{authorName}</p> */}
            {/* <p className="text-lg font-light text-gray-400">{authorTitle}</p> */}
          </div>
        </div>

        {/* <div className="text-gray-400 text-lg text-right">
          <p>{date}</p>
          <p>{time}</p>
        </div> */}
      </div>

      {/* Main Image */}
      <ImageSection
        src={`https://api.betuptip.com${image}`}
        alt="banner image"
      />

      {/* Content Description */}
      <p className="mt-2 text-lg">{description}</p>

      {/* Information Section */}
      {/* <InfoSection items={infoItems} /> */}

      {/* Content */}
      <div className="py-6 text-2xl font-normal">
        <p
          className="mb-8 leading-10"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></p>
      </div>

      {/* <div className="py-6 text-2xl font-normal">
        {content.map((paragraph, index) => (
          <p key={index} className="mb-8 leading-10">
            {paragraph}
          </p>
        ))}
      </div> */}
    </div>
  );
};

export default MainArticle;
