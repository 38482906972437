import React from "react";
import { HeaderCard } from "../component/HeaderCard";
import MobileFooter from "../component/MobileFooter";
import { FaApple } from "react-icons/fa6";
import MobileHeaderCard from "../component/MobileHeaderCard";
import SideAds from "../component/SideAds";

const GetTheApp = () => {
  return (
    <div className="bg-[url('https://i.ibb.co/3pyr58R/betuptip.png')] object-fill bg-fixed bg-fill bg-center ">
      <div className="bg-black opacity-50 fixed  inset-0 z-2 w-screen h-screen"></div>

      <div className="relative">
        <SideAds />
        <HeaderCard />
        <MobileHeaderCard />
        <div className="lg:mx-96 mx-0 mt-0 text-white bg-black p-5">
          <div className="border-0 lg:border border-white border-opacity-30 flex flex-col justify-center items-center rounded-md py-5 mt-20">
            <p className="m-10 bg-green-600 px-4 py-3 font-bold text-xl">
              COMING SOON!!!
            </p>
            <p className="font-bold text-3xl p-2 pb-10">Get the BetUpTip App</p>
            <img
              src="https://i.ibb.co/PM9Dv5v/betuptipframe.png"
              alt="app"
              className="w-auto h-[450px]"
            />

            <div className="flex gap-5 items-center mt-[-16px]">
              <div className="bg-white rounded-md text-black flex items-center p-3 gap-2 border-2 ">
                <FaApple className="w-12 h-12" />
                <div className="">
                  <p className="text-lg font-semibold">Download on the</p>
                  <p className="font-bold text-xl mt-[-3px] ">App Store</p>
                </div>
              </div>

              <div className="bg-black rounded-md text-white flex items-center p-3.5 gap-4 border-2 border-white border-opacity-30">
                <img
                  src="https://i.ibb.co/St76BMd/googleplay.png"
                  alt="googleplayicon"
                  className="w-10 h-10"
                />
                <div className="">
                  <p className="text-lg font-semibold">GET IT ON</p>
                  <p className="font-bold text-xl mt-[-3px]">Google Play</p>
                </div>
              </div>
            </div>

            <button className="mt-20 bg-yellow-600 hover:bg-green-600 hover:text-white rounded-md lg:py-3 py-5 px-32 lg:px-16 font-bold text-lg text-black">
              Get the BetUpTip App
            </button>

            <p className="justify-center items-center flex py-10 px-20 lg:w-[80%] w-full text-center lg:text-sm text-lg">
              For an even better Betuptip experience on your mobile device you
              can download the Betuptip app. The Betuptip app includes all the
              features available on Betuptip.com, plus much more. The Betuptip
              app is available on iOS and Android devices
            </p>
          </div>

          <footer className="flex flex-col justify-center items-center py-10">
            <p>Download the BetUpTip Mobile App</p>
            <p className="justify-center items-center flex py-10 px-20 w-full text-center lg:text-sm text-lg">
              The world&apos;s leading Bet Tip service for football directly to
              your mobile device. Betuptip.com offers a responsive website for
              optimal use on mobile devices, plus free mobile Betuptip apps for
              iPhone and Android. Get your live football tips on your mobile
              plus NBA mobile Betuptips, NHL mobile live scores and mobile
              Betuptip for tennis and cricket.
            </p>
            <p className="text-xs mb-8">© 2023-2024 BetUpTip Limited</p>
          </footer>
          <MobileFooter />
        </div>
      </div>
    </div>
  );
};

export default GetTheApp;
