import React from "react";
import { NavLink } from "react-router-dom";
import { LiaLanguageSolid } from "react-icons/lia";

const SubHeader = () => {
  return (
    <div>
      <div className="lg:mx-96 mx-0 mt-0  text-white bg-black p-4 ">
        <div className="flex items-center mx-auto justify-between mt-28 lg:mt-0 lg:py-3">
          <div className="overflow-x-auto scroll-smooth">
            {" "}
            <ul className="flex gap-3 whitespace-nowrap">
              <NavLink
                to="/news"
                className={({ isActive }) =>
                  isActive
                    ? "bg-white text-black font-semibold py-3 px-4 text-2xl  rounded-full"
                    : "bg-gray-900 text-gray-400 hover:bg-white hover:text-black font-semibold py-3 px-4  text-2xl  rounded-full"
                }
              >
                {" "}
                All
              </NavLink>
              <NavLink
                to="/Football"
                className={({ isActive }) =>
                  isActive
                    ? "bg-white text-black font-semibold py-3 px-4 text-2xl  rounded-full"
                    : "bg-gray-900 text-gray-400 hover:bg-white hover:text-black font-semibold py-3 px-4  text-2xl  rounded-full"
                }
              >
                {" "}
                Football
              </NavLink>
              <NavLink
                to="/Predictions"
                className={({ isActive }) =>
                  isActive
                    ? "bg-white text-black font-semibold py-3 px-4 text-2xl  rounded-full"
                    : "bg-gray-900 text-gray-400 hover:bg-white hover:text-black font-semibold py-3 px-4  text-2xl  rounded-full"
                }
              >
                Predictions
              </NavLink>
              <NavLink
                to="/MatchReports"
                className={({ isActive }) =>
                  isActive
                    ? "bg-white text-black font-semibold py-3 px-4 text-2xl  rounded-full"
                    : "bg-gray-900 text-gray-400 hover:bg-white hover:text-black font-semibold py-3 px-4  text-2xl  rounded-full"
                }
              >
                {" "}
                Match reports
              </NavLink>
            </ul>
          </div>

          <p className="flex items-center gap-1 border border-1 border-white rounded-md border-opacity-30 p-2">
            <span>
              <LiaLanguageSolid />
            </span>
            EN
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
