import React, { FormEvent, useState, ChangeEvent } from "react";
import Layout from "../hoc/Layout";
import { ButtonLoader } from "../component/Loader";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { login } from "../store/slices/authSlice";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import SocialLoginButton from "../component/SocialLoginButton";
import useAlert from "../Hooks/useAlert";
import { axiosInstance, handleError } from "../utilities/helpers";
import { LoginResponse } from "../utilities/types";
import Alert from "../component/Alert";

const LOGIN_URL = "/auth/login";
const NAVIGATION_DELAY = 2400;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [alert, setAlert] = useAlert();
  const { formState, handleChange } = useForm({
    email: "",
    password: "",
  });

  const loginUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance()
      .post<LoginResponse>(LOGIN_URL, formState)
      .then(({ data }) => {
        setAlert({
          message: data.message,
          status: data.status,
        });

        const { data: loginResponse } = data;
        const { is_premium, email, token, subscription_count } = loginResponse;
        setTimeout(() => {
          dispatch(login({ token, is_premium, email, subscription_count }));
          navigate("/dashboard");
        }, NAVIGATION_DELAY);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register">
            <div className="register__img"></div>
            <div className="register__form">
              <h3>Login</h3>
              <form className="form" onSubmit={loginUser}>
                <FormInput
                  id="email"
                  type="email"
                  label="Email Address"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="password"
                  type="password"
                  label="Password"
                  value={formState.password}
                  onChange={handleInputChange}
                />
                <div className="input__group submit">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`btn btn-pry btn-lrg btn-round btn-block ${
                      loading ? "btn-disabled" : ""
                    }`}
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
                <div className="input__group">
                  <div className="flex flex--between">
                    <p>
                      Have an account already? <a href="/register">Register</a>
                    </p>
                    <p className="text-right">
                      <a href="/forgot-password">Forgot password?</a>
                    </p>
                  </div>
                </div>
                <SocialLoginButton />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
