import React from "react";

const SideAds = () => {
  return (
    <div>
      {" "}
      {/* words */}
      <div className="relative hidden lg:flex w-full">
        {/* Left Logo */}
        <div className="absolute left-0 py-96">
          <img
            src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
            alt="betuptip logo"
            className="w-auto h-12 px-40"
          />

          <div className="text-white font-semibold flex flex-col items-center pt-40">
            <div className="text-center py-10">
              {" "}
              <h1 className="capitalize text-4xl font-extrabold">Get weekly</h1>
              <h1 className="text-yellow-600 text-3xl font-bold py-5">
                Predictions
              </h1>
              <h1 className="uppercase text-green-500 text-5xl">2 odds</h1>
              <h1 className="text-5xl py-5">as low as</h1>
              <h1 className="text-yellow-600 text-5xl font-bold">₦2,000</h1>
            </div>

            <div className="flex flex-col items-center pt-10">
              <p className="text-xl">Join VIP</p>
              <button className="bg-green-500 p-5 rounded-full text-xl hover:bg-yellow-600 cursor-pointer hover:text-black">
                Get Started
              </button>
            </div>
          </div>
        </div>

        {/* Right Logo */}
        <div className="absolute right-0  py-96">
          <img
            src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
            alt="betuptip logo"
            className="w-auto h-12 px-40"
          />

          <div className="text-white font-semibold flex flex-col items-center pt-40">
            <div className="text-center py-10">
              {" "}
              <h1 className="capitalize text-4xl font-extrabold">
                Get weekend
              </h1>
              <h1 className="text-yellow-600 text-3xl font-bold py-5">
                Predictions
              </h1>
              <h1 className="uppercase text-green-500 text-5xl">6 - 8 odds</h1>
              <h1 className="text-5xl py-5">as low as</h1>
              <h1 className="text-yellow-600 text-5xl font-bold">₦25,000</h1>
            </div>

            <div className="flex flex-col items-center pt-10">
              <p className="text-xl">Join VIP</p>
              <button className="bg-green-500 p-5 rounded-full text-xl hover:bg-yellow-600 cursor-pointer hover:text-black">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideAds;
