import { useState, useEffect } from "react";
import { Alert, FetchError } from "../utilities/types";

const useAlert = (data: any | null = null, error: FetchError | null = null) => {
  const [alert, setAlert] = useState<Alert>({
    status: "failed",
    message: "",
  });

  useEffect(() => {
    if (data) return setAlert({ message: data.message, status: data.status });

    if (error)
      return setAlert({
        message: error.responseData?.message || error.message,
        status: "failed",
      });
  }, [data, error]);

  return [alert, setAlert] as const;
};

export default useAlert;
