import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../store/store";
import { ButtonLoader } from "./Loader";
import useAlert from "../Hooks/useAlert";
import Alert from "./Alert";

import {
  axiosInstance,
  formatCurrency,
  handleError,
} from "../utilities/helpers";

import { PaymentResponse, SubscriptionProps } from "../utilities/types";
const INITIALISE_PAYMENT_URL = "/payments";

const Subscription = ({ subscription }: SubscriptionProps) => {
  const navigate = useNavigate();
  const { accessToken, is_loggedIn } = useTypedSelector((state) => state.auth);

  const { benefits, plans, name } = subscription;
  const [alert, setAlert] = useAlert();
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscription = () => {
    if (!is_loggedIn) return navigate("/login");
    setIsLoading(true);
    axiosInstance(accessToken)
      .post<PaymentResponse>(INITIALISE_PAYMENT_URL, {
        amount: selectedPlan.price * 100,
        plan_name: name,
      })
      .then(({ data }) => {
        const paymentResponse = data.data;
        if (!paymentResponse.authorization_url) return navigate("/dashboard");
        window.location.href = paymentResponse.authorization_url;
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setIsLoading(false));
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlan(
      plans.find((plan) => plan.name === e.target.value) || plans[0]
    );
  };

  return (
    <div
      className={`subscription__card ${
        name === "Basic"
          ? "subscription__card--basic"
          : "subscription__card--gold"
      }`}
    >
      <Alert content={alert} />
      <div className="subscription__card-header">
        <h3>{formatCurrency(selectedPlan.price)}</h3>
        <p className={name !== "Basic" ? "text-sec" : ""}>{name}</p>

        {plans.length > 1 && (
          <select required value={selectedPlan.name} onChange={handleChange}>
            {plans.map((plan) => (
              <option key={plan._id} value={plan.name}>
                {plan.duration} days
              </option>
            ))}
          </select>
        )}
      </div>

      <ul className="subscription__features">
        {benefits.map((benefit, index) => (
          <li key={index} className="subscription__feature">
            {benefit}
          </li>
        ))}
      </ul>

      <div className="subscription__footer">
        <button
          type="submit"
          disabled={isLoading}
          className={`btn btn-round btn-outline-${
            name === "Basic" ? "blue" : "pry"
          } btn-block ${isLoading ? "btn-disabled" : ""}`}
          onClick={handleSubscription}
        >
          {isLoading ? <ButtonLoader /> : "Get Started"}
        </button>
      </div>
    </div>
  );
};

export default Subscription;
