import React from "react";
// import { IoIosFootball } from "react-icons/io";
import { IoNewspaper } from "react-icons/io5";
import { BiSolidDownload } from "react-icons/bi";
import { NavLink } from "react-router-dom";

export const HeaderCard = () => {
  return (
    <div className="">
      <header className="hidden lg:flex justify-between items-center mx-96  text-gray-400 bg-black px-8 border-b py-8 border-white border-opacity-30">
        <NavLink to="/">
          <img
            src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
            alt="betuptip logo"
            className="w-auto h-8"
          />
        </NavLink>
        <ul className="flex gap-16">
          {/* <NavLink
            to="/Livescore"
            className={({ isActive }) =>
              isActive
                ? "flex items-center gap-2 text-2xl font-bold text-yellow-600"
                : "flex items-center gap-2 text-2xl font-bold text-gray-400 hover:text-yellow-600"
            }
          >
            <span>
              <IoIosFootball className="w-10 h-10" />
            </span>
            Score
          </NavLink> */}

          <NavLink
            to="/News"
            className={({ isActive }) =>
              isActive
                ? "flex items-center gap-2 text-2xl font-bold text-yellow-600"
                : "flex items-center gap-2 text-2xl font-bold text-gray-400 hover:text-yellow-600"
            }
          >
            <span>
              <IoNewspaper className="w-10 h-10" />
            </span>
            News
          </NavLink>

          <NavLink
            to="/GetTheApp"
            className={({ isActive }) =>
              isActive
                ? "flex items-center gap-2 text-2xl font-bold text-yellow-600"
                : "flex items-center gap-2 text-2xl font-bold text-gray-400 hover:text-yellow-600"
            }
          >
            <span>
              <BiSolidDownload className="w-10 h-10" />
            </span>
            Get the App
          </NavLink>
        </ul>
        <div className="flex items-center gap-8">
          <div className="h-8 w-0.5 bg-white bg-opacity-30"></div>
          <NavLink to="/">
            <img
              src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
              alt="betuptip logo"
              className="w-auto h-8"
            />
          </NavLink>
        </div>
      </header>
    </div>
  );
};
