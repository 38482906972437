import React, { FormEvent, useState } from "react";
import Layout from "../hoc/Layout";
import { useTypedSelector } from "../store/store";
import { ButtonLoader } from "../component/Loader";
import Otp from "../component/Otp";
import useForm from "../Hooks/useForm";
import { axiosInstance, handleError } from "../utilities/helpers";
import { BaseResponse } from "../utilities/types";
import useAlert from "../Hooks/useAlert";
import { useNavigate } from "react-router-dom";
import Alert from "../component/Alert";

const VERIFY_PASSWORD = "/auth/verify-password-otp";

const VerifyPasswordReset = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useAlert();
  const [loading, setLoading] = useState(false);
  const { email } = useTypedSelector((state) => state.auth);
  const { formState, handleChange } = useForm({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  });

  const handleInputChange = (name: string, value: string) => {
    handleChange(name as keyof typeof formState, value);
  };

  const verifyPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const requestData = {
      email: `${email}`,
      otp: `${formState.dig1}${formState.dig2}${formState.dig3}${formState.dig4}${formState.dig5}${formState.dig6}`,
    };

    axiosInstance()
      .post<BaseResponse>(VERIFY_PASSWORD, requestData)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setLoading(false));
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="verify">
            <header className="heading">
              <h3 className="txt-primary">Forgot Password Verification</h3>
              <p>please enter the code we have sent to your email</p>
            </header>
            <form className="verify-block" onSubmit={verifyPassword}>
              <Otp otp={formState} setOtp={handleInputChange} />
              <div className="input__group txt-center">
                <button
                  type="submit"
                  className={`btn btn-round btn-pry btn-lrg ${
                    loading ? "btn-disabled" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyPasswordReset;
