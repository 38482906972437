import { LiaLanguageSolid } from "react-icons/lia";
import { SlCalender } from "react-icons/sl";
import { IoCloseSharp } from "react-icons/io5";
import { FaArrowTrendUp } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import React from "react";
import ScoreCards from "../component/ScoreCards";
import { HeaderCard } from "../component/HeaderCard";
import MobileHeaderCard from "../component/MobileHeaderCard";
import MobileFooter from "../component/MobileFooter";
import SideAds from "../component/SideAds";

const Scoreboard = () => {
  return (
    <div className="bg-[url('https://i.ibb.co/3pyr58R/betuptip.png')] object-fill bg-fixed bg-fill bg-center  ">
      <div className="bg-black opacity-50 fixed inset-0 z-2 w-screen h-screen"></div>
      <div>
        <div className=" relative">
          <SideAds />
          <HeaderCard />
          <MobileHeaderCard />
          <div className="lg:mx-96 mx-0 mt-0 text-white bg-black p-7">
            <div className="flex items-center mx-auto justify-between mt-28 mb-0 lg:mb-8 lg:mt-0">
              <p className="bg-white text-black font-semibold py-3 px-4  text-2xl rounded-full">
                Football
              </p>
              <p className="flex items-center gap-1 border border-1 border-white rounded-md border-opacity-30 p-2">
                <span>
                  <LiaLanguageSolid />
                </span>
                EN
              </p>
            </div>

            {/* scores */}
            <div className="border-0 lg:border border-white border-opacity-30 rounded-md p-3 mt-3 lg:p-8 lg:mt-3 ">
              <div className="flex justify-between items-center py-4 lg:py-3 border-b border-white border-opacity-30">
                <p className="font-bold text-black bg-white rounded-md p-1">
                  LIVE
                </p>
                <span>
                  <SlCalender />
                </span>
              </div>

              {/* ad banner */}
              <div className="bg-green-400 rounded-lg shadow-md lg:p-3 p-2 text-black mt-5">
                <div className="flex mx-auto items-center justify-between">
                  <FaArrowTrendUp className="bg-green-500 border border-green-500 p-1 rounded-md h-10 w-10" />
                  <p className="text-lg lg:text-xl">
                    <span className=" text-lg lg:text-xl font-semibold cursor-pointer">
                      ₦100,000! GET YOURS NOW!
                    </span>{" "}
                    Join & deposit at BetUpTip*
                  </p>
                  <IoCloseSharp />
                </div>

                <div className="flex mx-auto items-center justify-between lg:mt-10 mt-12">
                  <p className="text-[6px] lg:text-[8px] ">
                    *Terms apply. Bet responsibly. 18+
                  </p>
                  <p className="font-semibold ">BetUpTip</p>
                </div>
              </div>

              {/* add logo here */}
              <div className="flex mx-auto items-center justify-between mt-3 lg:mt-10 px-10">
                <div>
                  <p>NPLF</p>
                  <p>Nigeria</p>
                </div>
                <MdOutlineKeyboardArrowRight className="h-5 w-5 " />
              </div>
              <ScoreCards />
              <MobileFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
