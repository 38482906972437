import React from "react";

import MobileHeaderCard from "../component/MobileHeaderCard";
import SubHeader from "../component/SubHeader";
import MobileFooter from "../component/MobileFooter";
import NewsList from "../component/NewsList";
import { HeaderCard } from "../component/HeaderCard";
import SideAds from "../component/SideAds";

const WomenFootball = () => {
  return (
    <div className="bg-[url('https://i.ibb.co/3pyr58R/betuptip.png')] object-fill bg-fixed bg-fill bg-center">
      <div className="bg-black opacity-50 fixed  inset-0 z-2 w-screen h-screen"></div>
      <div className=" relative">
        <SideAds />
        <HeaderCard />
        <MobileHeaderCard />
        <SubHeader />
        <div className="lg:mx-96 mx-0 mt-0 text-white bg-black p-4">
          <NewsList />
        </div>

        <MobileFooter />
      </div>
    </div>
  );
};

export default WomenFootball;
