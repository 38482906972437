import React from "react";
import { NavLink } from "react-router-dom";

interface NewsCardProps {
  _id: string;
  image: string;
  title: string;
  description: string;
  tags?: string[];
  mobiletags?: string[];
  time?: string;
}

const NewsCard = ({
  _id,
  image,
  title,
  tags,
  description,
  mobiletags,
  time,
}: NewsCardProps) => {
  return (
    <div>
      <div className="py-3 lg:px-5 px-2 flex justify-between relative border-0 lg:border-b border-white border-opacity-30">
        <div className="flex gap-4">
          <img
            src={`https://api.betuptip.com/${image}`}
            alt=""
            className="rounded-lg w-auto lg:h-52 h-36 "
          />

          <div className="flex gap-4 lg:gap-10 flex-col">
            <p className="font-semibold text-2xl w-[100%] lg:w-[70%] cursor-pointer">
              <NavLink to={`/predictions/${_id}`}>{title}</NavLink>
            </p>
            <p className="text-2xl w-[100%] lg:w-[70%]">{description}</p>
            <div>
              {/* larger screens */}
              <ul className="hidden lg:flex gap-3">
                {tags &&
                  tags.map((tag, index) => (
                    <li
                      key={index}
                      className="bg-gray-900 text-gray-400 text-xl font-semibold py-1 px-3 rounded-full cursor-pointer"
                    >
                      {tag}
                    </li>
                  ))}
              </ul>

              {/* for mobile */}
              <ul className="lg:hidden flex gap-2">
                {mobiletags &&
                  mobiletags.map((mobiletags, index) => (
                    <li
                      key={index}
                      className="bg-gray-900 text-gray-400 text-xl font-semibold py-1 px-4 rounded-full cursor-pointer"
                    >
                      {mobiletags}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="absolute lg:bottom-12 bottom-4 lg:right-5 right-2">
          <p className="text-lg lg:font-medium font-semibold lg:text-base text-gray-400">
            {time}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
