import "../src/index.css";
import "./sass/style.scss";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Registration from "./pages/Registration";
import Dashboard from "./pages/Dashboard";
import PasswordReset from "./pages/PasswordReset";
// import News from "./pages/News";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPasswordVerify from "./pages/ForgotPasswordVerify";
// import NewsDetails from "./pages/NewsDetails";
import { useTypedSelector } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Terms from "./pages/Terms&Condition";
import About from "./pages/Aboutus";
import Profile from "./pages/Profile";
import Scoreboard from "./pages/Scoreboard";
import NewsPage from "./pages/NewsPage";
import GetTheApp from "./pages/GetTheApp";
import MatchReports from "./SubPages/MatchReports";
import Football from "./SubPages/Football";
import NewsRead from "./SubPages/NewsRead";
import WomenFootball from "./SubPages/WomenFootball";
import Predictions from "./SubPages/Predictions";

function App() {
  const { accessToken, subscription_count } = useTypedSelector(
    (state) => state.auth
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Home />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/livescore" element={<Scoreboard />}></Route>
        <Route path="/news" element={<NewsPage />}></Route>
        <Route path="/gettheapp" element={<GetTheApp />}></Route>
        <Route path="/matchreports" element={<MatchReports />}></Route>
        <Route path="/football" element={<Football />}></Route>
        <Route path="/newsread" element={<NewsRead />}></Route>
        <Route path="/womenfootball" element={<WomenFootball />}></Route>
        <Route path="/predictions/:id" element={<Predictions />}></Route>
        <Route
          path="/verify-email"
          element={accessToken ? <VerifyEmail /> : <Navigate to="/login" />}
        />
        <Route
          path="/forgot-password-verify"
          element={<ForgotPasswordVerify />}
        />
        <Route
          path="/dashboard"
          element={accessToken ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={accessToken ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          path="/pricing"
          element={
            !subscription_count ? <Navigate to="/dashboard" /> : <Pricing />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
