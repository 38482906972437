import React from "react";
import { useState } from "react";
import {
  // FaFootballBall,
  FaChartLine,
  FaFileAlt,
  // FaFemale,
} from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { FiAlignLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

const MobileHeaderCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);

  return (
    <div>
      <header className="lg:hidden bg-black py-10 fixed top-0 left-0 z-10 w-full">
        <div className="flex justify-center items-center">
          <FiAlignLeft
            onClick={toggleNavbar}
            className="text-gray-400 h-10 w-10 ml-7 "
          />

          <img
            src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
            alt="betuptip logo"
            className="w-auto h-10 mx-auto mr-5"
          />
        </div>
      </header>

      {/* Backdrop Blur - Only when sidebar is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all duration-300"
          style={{ zIndex: 40 }} // Makes sure the blur is behind the sidebar
        ></div>
      )}

      {/* mobile Sidebar Navbar */}
      <div
        className={`fixed top-0 left-0 w-[70%] h-full bg-black transition-transform ${
          isOpen ? "transform-none" : "-translate-x-full"
        }`}
        style={{ zIndex: 50 }}
      >
        {/* Close Button */}
        <div className="flex justify-between items-center px-6 py-4">
          <img
            src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
            alt="betuptip logo"
            className="w-auto h-10"
          />
          <FiX
            onClick={toggleNavbar}
            className="text-gray-400 h-8 w-8 cursor-pointer"
          />
        </div>

        {/* Nav links */}
        <ul className="text-white px-6">
          {/* <Link to="/football">
            {" "}
            <li className="flex items-center gap-4 py-3 hover:text-yellow-600">
              <FaFootballBall className="text-gray-400 h-10 w-10" />
              <p className="text-gray-400 text-3xl py-6">Football</p>
            </li>
          </Link> */}
          <Link to="/">
            <li className="flex items-center gap-3 py-3 hover:text-yellow-600">
              <FaChartLine className="text-gray-400 h-10 w-10" />
              <p className="text-gray-400 text-3xl py-6">Home</p>
            </li>
          </Link>

          <Link to="/News">
            {" "}
            <li className="flex items-center gap-3 py-3 hover:text-yellow-600">
              <FaFileAlt className="text-gray-400 h-10 w-10" />
              <p className="text-gray-400 text-3xl py-6">News</p>
            </li>
          </Link>
          {/* <Link to="/womenfootball">
            {" "}
            <li className="flex items-center gap-3 py-3 hover:text-yellow-600">
              <FaFemale className="text-gray-400 h-10 w-10" />
              <p className="text-gray-400 text-3xl py-6">Women's Football</p>
            </li>
          </Link> */}
        </ul>

        {/* Bottom Text */}
        <Link to="/GetTheApp">
          <div className="absolute flex items-center gap-6 bottom-40 left-48 transform -translate-x-1/2 text-gray-400 text-sm">
            <img
              src="https://i.ibb.co/gyTtwdw/betuptip-logo.png"
              alt="betuptip logo"
              className="w-auto h-12 right-1/2 bg-slate-800 p-4 rounded-lg"
            />
            <p className="text-gray-400 text-2xl font-semibold">
              Click here to get the Betuptip Mobile app
            </p>
          </div>
        </Link>
      </div>
      {/* sidebar end */}
    </div>
  );
};

export default MobileHeaderCard;
